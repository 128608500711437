import React from 'react';
import { Redirect } from '@reach/router';

import FadeTransitionRouter from '../../router/FadeTransitionRouter';

import NotificationHandler from './NotificationHandler';

import NotFound from '../../layouts/NotFound';

import { useUser } from '../../context/user-context';

import dictionary from '../../utils/dictionary';

import Reservations from '../../layouts/Reservations';
import ReservationsCreate from '../../layouts/ReservationsCreate';
import WalkIn from '../../layouts/WalkIn';
import Pending from '../../layouts/Pending';
import ReservationsEdit from '../../layouts/ReservationsEdit';

import Clients from '../../layouts/Clients';
import ClientsEdit from '../../layouts/ClientsEdit';

import Offers from '../../layouts/Offers';
import OffersEdit from '../../layouts/OffersEdit';
import OffersCreate from '../../layouts/OffersCreate';

import Restaurant from '../../layouts/Restaurant';

import Charges from '../../layouts/Charges';
import Statistics from '../../layouts/Statistics';
import Statisticsreserva from '../../layouts/Statisticsgrapreserva';
import Staff from '../../layouts/Staff';
import StaffEdit from '../../layouts/StaffEdit';
import StaffCreate from '../../layouts/StaffCreate';

import Comments from '../../layouts/Comments2';
import Comments2 from '../../layouts/Comments';
import Terms from '../../layouts/Terms';
import Privacy from '../../layouts/Privacy';
import Floormap from '../../layouts/Floormap';

const AuthenticatedApp = () => {
  const token = useUser();
  // console.log(process.env.NODE_ENV);
  const renderRedirectionFromUnauthenticatedRoutes = () => (
    <>
      {token && (
        <Redirect from="/" to="/reservaciones" noThrow />
      )}
      <Redirect from="/olvide-password" to="/" noThrow />
      <Redirect from="/recuperar-password" to="/" noThrow />
      <Redirect from="/login" to="/" noThrow />
    </>
  );

  const renderForAdmins = () => (
    <>
      <Reservations path="/reservaciones" />
      <Floormap path="/floormap" />
      <ReservationsCreate path="/crear-reservacion" />
      <WalkIn path="/WalkIn" />
      <Pending path="/Pending" />
      <ReservationsEdit path="/editar-reservacion/:id/:table/:date" />
      <Clients path="/clientes" />
      <Offers path="/ofertas" />
      <OffersEdit path="/editar-oferta/:id" />
      <OffersCreate path="/crear-oferta" />
      <ClientsEdit path="/cliente/:typeOfClient/:id" />
      <Restaurant path="/restaurante" />
      <Charges path="/cobros" />
      <Staff path="/staff" />
      <Staff path="/staff" />
      <Statistics path="/estadisticas" />
      <Statisticsreserva path="/grapreservas" />
      <StaffEdit path="/editar-staff/:id" />
      <StaffCreate path="/crear-staff" />
      <Comments2 path="/comentarios2" />
      <Comments path="/comentarios" />
      <Terms path="/terms" />
      <Privacy path="/privacy" />
    </>
  );
  const renderForHostess = () => (
    <>
      <Reservations path="/reservaciones" />
      <ReservationsCreate path="/crear-reservacion" />
      <WalkIn path="/WalkIn" />
      <ReservationsEdit path="/editar-reservacion/:id/:table/:date" />
      <Clients path="/clientes" />
      <ClientsEdit path="/cliente/:typeOfClient/:id" />
      <Comments path="/comentarios" />
      <Comments2 path="/comentarios2" />
      <Terms path="/terms" />
      <Privacy path="/privacy" />
    </>
  );

  const renderAlerts = () => (
    <>
      <NotificationHandler />
    </>
  );

  return (
    <div className="authenticated-app">
      <FadeTransitionRouter>
        {renderRedirectionFromUnauthenticatedRoutes()}
        {
          dictionary.userType[2] === localStorage.getItem(`${process.env.REACT_APP_ROLE_KEY}`)
            ? renderForAdmins() : null
        }
        {
          dictionary.userType[3] === localStorage.getItem(`${process.env.REACT_APP_ROLE_KEY}`)
            ? renderForHostess() : null
        }
        <NotFound default />
      </FadeTransitionRouter>
      {renderAlerts()}
      {/* Esta línea es para que no funcione en local alertas
      {process.env.NODE_ENV !== 'a' && (
       renderAlerts()
      )} */}
    </div>
  );
};

export default AuthenticatedApp;
