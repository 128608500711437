/* eslint-disable */



import React, { useState, useEffect, useContext } from 'react';
import { HuePicker, AlphaPicker } from 'react-color';
import FloormapContext from '../../layouts/Floormap/FloormapContext/FloormapContext';
import FloormapDistribucionContext from '../FloormapDistribucion/FloormapDistribucionContext/FloormapDistribucionContext';
import { Switch } from 'antd';

import IconButton from '@material-ui/core/IconButton';

import relleno from '../../images/floormapElements/botonesAndOpciones/001-bote-de-pintura.png';
import contorno from '../../images/floormapElements/botonesAndOpciones/002-contorno.png';
import tamano from '../../images/floormapElements/botonesAndOpciones/003-medida.png';
import line0 from '../../images/floormapElements/botonesAndOpciones/line-0.png';
import line1 from '../../images/floormapElements/botonesAndOpciones/line-1.png';
import line2 from '../../images/floormapElements/botonesAndOpciones/line-2.png';
import line3 from '../../images/floormapElements/botonesAndOpciones/line-3.png';
import line4 from '../../images/floormapElements/botonesAndOpciones/line-4.png';



import './FloormapShapeMenu.scss';

import {
  Button,
  Box,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  Accordion,
  Container,
  Grid,
  TextField,
  CircularProgress,
  Hidden,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  NativeSelect,
  Checkbox,
  InputLabel,
  Typography,
  Menu
} from '@material-ui/core';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { array } from 'prop-types';

const FloormapShapeMenu = () => {
  
  const { arrayRehacer, setArrayRehacer, arrayDeshacer, setArrayDeshacer, id, setId, images, setImages, selectedItem, setSelectedItem, imageNode, setImageNode } = useContext(FloormapDistribucionContext);

  //Menu ancho de contorno

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const abrirMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const cerrarMenu = () => {
    setAnchorEl(null);
  };

  const selectSize = (size) => {
    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = imageNode.attrs.idNum;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.strokeWidth = size;
        imageNode.attrs.strokeWidth = image.strokeWidth;
      }
      return image;
    }));

    setAnchorEl(null);
  }
  
  //Color relleno
  const [color, setColor] = useState({
    displayColorPicker: false,
    color: {
      "r": 0,
      "g": 0,
      "b": 0,
      "a": 0
    }
  });

  //Color contorno

  const [colorContorno, setColorContorno] = useState({
    displayColorPicker: false,
    color: {
      "r": 0,
      "g": 0,
      "b": 0,
      "a": 0
    }
  });


  const renovarColorRelleno = () =>{

    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = imageNode.attrs.idNum;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.fill = `rgba(${color.color.r},${color.color.g},${color.color.b},${color.color.a})`;
        imageNode.attrs.fill = image.fill;
      }
      return image;
    }));
    
  }

  const renovarColorContorno = () =>{

    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = imageNode.attrs.idNum;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.stroke = `rgba(${colorContorno.color.r},${colorContorno.color.g},${colorContorno.color.b},${colorContorno.color.a})`;
        imageNode.attrs.stroke = image.stroke;
      }
      return image;
    }));
    
  }

  const clickRelleno = () => {

    setColor({
      displayColorPicker: true,
      color: color.color
    });
  }

  const clickContorno = () => {

    setColorContorno({
      displayColorPicker: true,
      color: colorContorno.color
    });
  }

  const cerrarModalRelleno = () => {
    setColor({
      displayColorPicker: false,
      color: color.color
    });
  }

  const cerrarModalContorno = () => {
    setColorContorno({
      displayColorPicker: false,
      color: colorContorno.color
    });
  }

  const manejadorCambioRelleno = (colorr) => {
    console.log(colorr);    
    setColor({
      displayColorPicker: color.displayColorPicker,
      color: colorr.rgb
    });
  }

  const manejadorCambioContorno = (colorr) => {
    console.log(colorr);    
    setColorContorno({
      displayColorPicker: colorContorno.displayColorPicker,
      color: colorr.rgb
    });
  }
  

  return(
    <>
      <div className='dFlex flex-row align-center '>
        <IconButton
          variant="outlined"
          className='floormap-icon2'
          onClick={clickRelleno}
        >
          <img src={relleno} alt="relleno" width="40%" height="100%" />
          <div className='w100'>
            <Typography className='text-tiny2' >Relleno</Typography>
          </div>
        </IconButton>
        { color.displayColorPicker ? 
            <>
              <div className='encima'>
                  <HuePicker className="color-slider" color={ color.color } onChangeComplete={renovarColorRelleno} onChange={manejadorCambioRelleno}  /> 
                  <AlphaPicker className="alpha-slider" color={ color.color } onChangeComplete={renovarColorRelleno} onChange={manejadorCambioRelleno} />
              </div> 
                <div className='cubrir' onClick={ cerrarModalRelleno }/>
            </>
           : null }
          { colorContorno.displayColorPicker ? 
            <>
              <div className='encima'>
                  <HuePicker className="color-slider" color={ colorContorno.color } onChangeComplete={renovarColorContorno} onChange={manejadorCambioContorno}  /> 
                  <AlphaPicker className="alpha-slider" color={ colorContorno.color } onChangeComplete={renovarColorContorno} onChange={manejadorCambioContorno} />
              </div> 
                <div className='cubrir' onClick={ cerrarModalContorno }/>
            </>
           : null }
        <IconButton
          variant="outlined"
          className='floormap-icon2'
          onClick={clickContorno}
        >
          <img src={contorno} alt="contorno" width="40%" height="100%" />
          <div className='w100'>
            <Typography className='text-tiny2' >Contorno</Typography>
          </div>
        </IconButton>
        <IconButton
          variant="outlined"
          className='floormap-icon2'
          onClick={abrirMenu}
        >
          <img src={tamano} alt="tamano" width="50%" height="100%" />
          <div className='w100'>
            <Typography className='text-tiny2' >Tamaño del contorno</Typography>
          </div>
        </IconButton>
        <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={cerrarMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem className='container-line-image' onClick={() => {selectSize(0)}}>
          <img src={line0} alt="line-0" className='line-image' />
        </MenuItem>
        <MenuItem className='container-line-image' onClick={() => {selectSize(1)}}>
          <img src={line1} alt="line-1" className='line-image' />
        </MenuItem>
        <MenuItem className='container-line-image' onClick={() => {selectSize(2)}}>
          <img src={line2} alt="line-2" className='line-image' />
        </MenuItem>
        <MenuItem className='container-line-image' onClick={() => {selectSize(3)}}>
          <img src={line3} alt="line-3" className='line-image' />
        </MenuItem>
        <MenuItem className='container-line-image' onClick={() => {selectSize(4)}}>
          <img src={line4} alt="line-4" className='line-image' />
        </MenuItem>
      </Menu>
      </div>
    </>
  )

}

export default FloormapShapeMenu;