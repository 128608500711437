import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  Button,
  Typography,
} from '@material-ui/core/';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import isTruthy from '../../utils/isTruthy';
import SnackbarAlert from '../SnackbarAlert';

import './DialogEditRestaurantSector.scss';

ReactModal.setAppElement('#root');

const DialogEditRestaurantSector = ({
  isOpen, onNo, data,
}) => {
  const [title] = useState('Sector');
  const [isLoading, setIsLoading] = useState(true);
  const [sectorCatalogs] = useState([
    { id: '', zone_id: '', name: 'Seleccionar sector' },
    { id: '1', zone_id: '1', name: 'Naco' },
    { id: '2', zone_id: '1', name: 'Piantini' },
    { id: '5', zone_id: '1', name: 'Bella vista' },
    { id: '6', zone_id: '1', name: 'Evaristo Morales' },
    { id: '7', zone_id: '1', name: 'Arroyo Hondo Viejo' },
    { id: '8', zone_id: '1', name: 'Zona Colonial' },
    { id: '9', zone_id: '1', name: 'La caleta' },
    { id: '10', zone_id: '1', name: 'Galería 360' },
    { id: '11', zone_id: '1', name: 'La Esperilla' },
    { id: '12', zone_id: '1', name: 'Cuesta Hermosa' },
    { id: '13', zone_id: '1', name: 'Gazcue' },
    { id: '14', zone_id: '1', name: 'Paraiso' },
    { id: '15', zone_id: '1', name: 'Mirador Norte' },
    { id: '16', zone_id: '1', name: 'Serrallés' },
    { id: '17', zone_id: '1', name: 'San Isidro' },
    { id: '18', zone_id: '1', name: 'Mirador Sur' },
    { id: '19', zone_id: '1', name: 'Los cacicazgos' },
    { id: '20', zone_id: '1', name: 'Colina centro' },
    { id: '21', zone_id: '1', name: 'Mata hambre' },
    { id: '22', zone_id: '1', name: 'Ensanche Quisqueya' },
    { id: '23', zone_id: '1', name: 'Nuñez Cáceres' },
    { id: '24', zone_id: '1', name: 'Renacimiento' },
    { id: '25', zone_id: '1', name: 'Plaza Duarte' },
    { id: '26', zone_id: '1', name: 'Zona Oriental' },
    { id: '27', zone_id: '1', name: 'San Isidro' },
    { id: '28', zone_id: '1', name: 'Nuñez Caceres' },
    { id: '29', zone_id: '1', name: 'El Cacique' },
    { id: '30', zone_id: '1', name: 'El Millón' },
    { id: '31', zone_id: '1', name: 'Los Prados' },

  ]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  useEffect(() => {
    if (isOpen) {
      setIsLoading(false);
    }
  }, [data, isOpen]);

  const submitChange = async (e, value) => {
    console.log('Valor seleccionado:', value); // Agregar esta línea

    const body = {
      sector_id: value,
    };
    try {
      const { success, message } = await RestaurantsApi.editSector(body);
      console.log('success', success);
      console.log(message);
      if (isTruthy(success)) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        // setSector(value);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    // Do something with the selected value
    submitChange(event, selectedValue);
  };
  const renderModal = () => (
    <ReactModal
      isOpen={isOpen}
      className="dialog-edit-restaurant-cost-modal"
      overlayClassName="dialog-edit-restaurant-cost-modal__overlay"
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <div className="dialog-edit-restaurant-cost-modal__content ">
        <div className="dialog-edit-restaurant-cost-modal__text-container">
          <div className="dialog-edit-restaurant-cost-modal__title">
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </div>
          <div className="dialog-edit-restaurant-cost-modal__body">
            <div className="w100">
              <select onChange={handleSelectChange} className="customselect">
                {sectorCatalogs.map((sector) => (
                  <option value={sector.id}>{sector.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="dialog-edit-restaurant-cost-modal__cta-container">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={onNo}
          >
            CERRAR
          </Button>
        </div>
      </div>
    </ReactModal>
  );

  return (
    <>
      {!isLoading && renderModal()}
    </>
  );
};

DialogEditRestaurantSector.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    field: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }),
};
DialogEditRestaurantSector.defaultProps = {
  isOpen: false,
  onNo: '',
  data: {
    field: '',
    value: {},
  },
};

export default DialogEditRestaurantSector;
