/* eslint-disable */
import React from 'react';

import PropTypes from 'prop-types';
import './DialogHistory.scss';
import ReactModal from 'react-modal';
import {
  Button,
  Box,
  Modal
} from '@material-ui/core/';

ReactModal.setAppElement('#root');

const DialogHistory = ({
  isOpen, onNo, 
}) => (
  <Modal
    keepMounted
    open={isOpen}
    onClose={onNo}
    aria-labelledby="keep-mounted-modal-title"
    aria-describedby="keep-mounted-modal-description"
  >
    <Box className='modal__box2'>
      <div className="dialog-email-modal__content__header leyenda-container-title">
        <h3 className="dialog-email-modal__content__header__title title leyenda-center">
          LEYENDA
        </h3>

      </div>
      <div className="dialog-email-modal__content__body dialog-email-modal__content">
        <div className='mb-1 bold-font font-text'>
          Significado del color de las mesas:
        </div>
        <div className="leyendarow-floormap">
          <span className="leyenda-floormap azuloscuro" />
          <div className='font-text'>
            Las mesas son de color azul cuando está reservada y espera la llegada del cliente.
          </div>
        </div>
        <div className="leyendarow-floormap">
          <span className="leyenda-floormap rojoClaro" />
          <div className='font-text'>
            Las mesas son de color rojo cuando el cliente ya está sentado.
          </div>
        </div>
        <div className="leyendarow-floormap">
          <span className="leyenda-floormap2 rojoClaro" />
          <span className="leyenda-floormap amarillo" />
          <div className='font-text'>
            Las mesas son de color roja y amarillo cuando está reservada dos veces en el mismo turno.
          </div>
        </div>
        <div className="leyendarow-floormap">
          <span className="leyenda-floormap rosado" />
          <div className='font-text'>
            Las mesas son de color rosada cuando han sido bloqueadas y no pueden ser reservadas.
          </div>
        </div>
        <div className="leyendarow-floormap">
          <span className="leyenda-floormap blanco" />
          <div className='font-text'>
            Las mesas son de color blanco cuando están disponibles.
          </div>
        </div>
      </div>
      <div className="pt-3e dialog-email-modal__content__body dialog-email-modal__content">
        <div className='mb-1 bold-font font-text'>
          Significado de los puntos:
        </div>
        <div className="leyendarow-floormap">
          <span className="leyenda-floormap rojo" />
          <div className='font-text'>
            Los puntos rojo oscuro: Mesas asignadas por el personal del restaurante.
          </div>
        </div>
        <div className="leyendarow-floormap">
          <span className="leyenda-floormap verde" />
          <div className='font-text'>
            Los puntos verde: Mesas asignadas por la plataforma.
          </div>
        </div>
        <div className="leyendarow-floormap">
          <span className="leyenda-floormap morado" />
          <div className='font-text'>
            Los puntos morado para mesas rojo-amarillo: Este punto aparece si el cliente de la primera reserva de estas mesas no ha llegado; una vez llegue dicho cliente, este punto desaparece.
          </div>
        </div>
      </div>
    </Box>
  </Modal>
);

DialogHistory.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  data: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
};
DialogHistory.defaultProps = {
  isOpen: false,
  onNo: '',
  data: '',
};
export default DialogHistory;
