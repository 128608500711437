/* eslint-disable */

import './SpinnerLoader.scss';

const SpinnerLoader = () => {

  return (
    <div className='spinner-overlay'>
      {
        <span className='spinner-loader'></span>
      }
    </div>
  );
};
export default SpinnerLoader;
