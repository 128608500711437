import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import {
  Paper,
  Grid,
  Badge,
  Box,
} from '@material-ui/core/';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import FloormapGestionReservacionesContext from '../FloormapGestionReservaciones/FloormapGestionReservacionesContext/FloormapGestionReservacionesContext';

import * as ReservationsApi from '../../api/restaurants/reservations';
import isTruthy from '../../utils/isTruthy';

import './FloormapDatepicker.scss';

export const styles = makeStyles(() => ({ // define CSS for different date types
  notInThisMonthDayPaper: {
    width: '35px',
    height: '35px',
    backgroundColor: '#eeeeee',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 0,
    padding: '1px',
  },
  normalDayPaperGreen: {
    width: '35px',
    height: '35px',
    backgroundColor: '#fff',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 0,
    padding: '1px',
    cursor: 'pointer',
    color: '#187616',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedDayPaperGreen: {
    width: '30px',
    height: '30px',
    backgroundColor: '#fff',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 25,
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'black',
    padding: '2px',
    cursor: 'pointer',
    color: '#187616',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  todayPaperGreen: {
    width: '35px',
    height: '35px',
    backgroundColor: '#e5e5e5',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 25,
    padding: '1px',
    cursor: 'pointer',
    color: '#187616',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  normalDayPaperRed: {
    width: '35px',
    height: '35px',
    backgroundColor: '#fff',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 0,
    padding: '1px',
    cursor: 'pointer',
    color: '#cb141d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedDayPaperRed: {
    width: '30px',
    height: '30px',
    backgroundColor: '#fff',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 25,
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'black',
    padding: '2px',
    cursor: 'pointer',
    color: '#cb141d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  todayPaperRed: {
    width: '35px',
    height: '35px',
    backgroundColor: '#e5e5e5',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 25,
    padding: '1px',
    cursor: 'pointer',
    color: '#cb141d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  normalDayPaperYellow: {
    width: '35px',
    height: '35px',
    backgroundColor: '#fff',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 0,
    padding: '1px',
    cursor: 'pointer',
    color: '#ed8123',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedDayPaperYellow: {
    width: '30px',
    height: '30px',
    backgroundColor: '#fff',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 25,
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'black',
    padding: '2px',
    cursor: 'pointer',
    color: '#ed8123',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  todayPaperYellow: {
    width: '35px',
    height: '35px',
    backgroundColor: '#e5e5e5',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 25,
    padding: '1px',
    cursor: 'pointer',
    color: '#ed8123',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hasReservations: {
    position: 'relative',
  },
}));

const FloormapDatepicker = ({
  isOpen, setIsOpen,
}) => {
  const { apiCallConfig, setApiCallConfig } = useContext(FloormapGestionReservacionesContext);
  const classes = styles(); // import those CSS
  const today = new Date(); // just Date object of today
  // parse string to date object using moment.js
  const [currentDate, setCurrentDate] = useState(moment(apiCallConfig.date).toDate());
  const [reservationsPerMonth, setReservationsPerMonth] = useState([]);
  const [status, setStatus] = useState({
    loading: true,
    hasData: false,
    error: false,
  });
  const prepareMonthData = (data) => {
    const keys = Object.keys(data);
    const greenDays = [];
    const redDays = [];
    const yellowDays = [];
    const hasReservations = [];
    const waitingList = [];
    const automatic = [];
    const blocked = [];
    const cancelled = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keys.length; i += 1) {
      const day = data[keys[i]];
      if (day.numPeople >= 1) {
        hasReservations.push(Number(keys[i]));
      }
      if (day.automatic >= 1) {
        automatic.push(Number(keys[i]));
      }
      if (day.waitinglist >= 1) {
        waitingList.push(Number(keys[i]));
      }
      if (day.cancelled >= 1) {
        cancelled.push(Number(keys[i]));
      }
      if (day.blocked >= 1) {
        blocked.push(Number(keys[i]));
      }
      switch (day.color) {
        case 'green':
          greenDays.push(Number(keys[i]));
          break;
        case 'red':
          redDays.push(Number(keys[i]));
          break;
        case 'yellow':
          yellowDays.push(Number(keys[i]));
          break;
        default:
          greenDays.push(Number(keys[i]));
          break;
      }
    }
    setReservationsPerMonth({
      greenDays, redDays, yellowDays, hasReservations, waitingList, automatic, blocked, cancelled,
    });
    setStatus({
      loading: false,
      hasData: true,
      error: false,
    });
  };
  useEffect(() => {
    if (isOpen) {
      const getReservationsPerMonth = async () => {
        try {
          const { success, data } = await ReservationsApi
            .getReservationsPerMonth(moment(currentDate).format('YYYY'), moment(currentDate).format('MM'));
          if (isTruthy(success)) {
            prepareMonthData(data);
            console.log(data);
          } else {
            setStatus({
              loading: false,
              hasData: true,
              error: false,
            });
          }
        } catch (error) {
          setStatus({
            loading: false,
            hasData: true,
            error: false,
          });
        }
      };
      getReservationsPerMonth();
    }
  }, [currentDate, isOpen]);
  const onMonthChange = (month) => {
    const newMonth = format(new Date(month), 'yyyy-MM-dd');
    setCurrentDate(newMonth);
  };
  const onNewDateSelected = (newDateSelected) => {
    const updateconfig = JSON.parse(localStorage.getItem('ReservationsConfig'));
    updateconfig.currentPage = 1;
    updateconfig.lastPage = null;
    updateconfig.from = 1;
    localStorage.setItem('ReservationsConfig', JSON.stringify(updateconfig));
    const currentHourSelected = localStorage.getItem('ReservationsConfigDate');
    const newDate = format(new Date(newDateSelected), 'yyyy-MM-dd');
    const newConfigDate = `${newDate} ${currentHourSelected.substring(11, currentHourSelected.length)}`;
    localStorage.setItem('ReservationsConfigDate', newConfigDate);
    setApiCallConfig({
      ...apiCallConfig,
      date: newDate,
      currentPage: 1,
      lastPage: null,
      from: 1,
    });
  };
  const assignClasses = (isSelected, isToday, dayHasReservations, color) => {
    const classesByColor = {
      Green: [
        classes.normalDayPaperGreen,
        classes.selectedDayPaperGreen,
        classes.todayPaperGreen,
      ],
      Red: [
        classes.normalDayPaperRed,
        classes.selectedDayPaperRed,
        classes.todayPaperRed,
      ],
      Yellow: [
        classes.normalDayPaperYellow,
        classes.selectedDayPaperYellow,
        classes.todayPaperYellow,
      ],
    };
    const assignedClasses = [];
    if (isSelected) {
      assignedClasses.push(classesByColor[color][1]);
    }
    if (isToday) {
      assignedClasses.push(classesByColor[color][2]);
    } else {
      assignedClasses.push(classesByColor[color][0]);
    }
    if (dayHasReservations) {
      assignedClasses.push('hasReservations');
    }
    return assignedClasses;
  };
  // eslint-disable-next-line no-unused-vars
  const getDayElement = (day, selectedDate, isInCurrentMonth, dayComponent) => {
    const isGreen = reservationsPerMonth.greenDays.includes(day.getDate()); // generate boolean
    const isRed = reservationsPerMonth.redDays.includes(day.getDate());
    const isYellow = reservationsPerMonth.yellowDays.includes(day.getDate());
    const isSelected = day.getDate() === selectedDate.getDate();
    const isToday = day.getDate() === today.getDate() && day.getMonth() === today.getMonth();
    const dayHasReservations = reservationsPerMonth.hasReservations.includes(day.getDate());
    const waitinglist = reservationsPerMonth.waitingList.includes(day.getDate());
    const automatic = reservationsPerMonth.automatic.includes(day.getDate());
    const cancelled = reservationsPerMonth.cancelled.includes(day.getDate());
    const blocked = reservationsPerMonth.blocked.includes(day.getDate());
    console.log('holaaaaaaaaaaaaaaaaaa');
    console.log(cancelled);
    console.log(reservationsPerMonth.cancelled);
    let dateTile;

    if (isInCurrentMonth) { // conditionally return appropriate Element of date tile.
      if (isGreen) {
        dateTile = (
          <Paper
            // eslint-disable-next-line no-nested-ternary
            className={assignClasses(
              isSelected,
              isToday,
              dayHasReservations,
              'Green',
            )}
          >
            {(waitinglist && !cancelled && !blocked && (
              <Badge badgeContent=" " variant="dot" color="primary">
                {(automatic > 0 && (
                  <Badge badgeContent=" " variant="dot" color="secondary" />
                ))}
                <Grid item>
                  {day.getDate()}
                </Grid>
              </Badge>
            ))}
            {(!waitinglist && !cancelled && !blocked && (
              <>
                {(automatic > 0 && (
                  <Badge badgeContent=" " variant="dot" color="secondary">
                    <Grid item>
                      {day.getDate()}
                    </Grid>
                  </Badge>
                ))}
                {(automatic <= 0 && (
                  <Grid item>
                    {day.getDate()}
                  </Grid>
                ))}
              </>
            ))}
            {(cancelled && !blocked && (
              <Badge badgeContent=" " variant="dot" classes={{ badge: 'oscuro' }}>
                {(automatic > 0 && (
                  <Badge badgeContent=" " variant="dot" color="secondary" />
                ))}
                {(waitinglist && (
                  <Badge badgeContent=" " variant="dot" color="primary" />
                ))}
                <Grid item>
                  {day.getDate()}
                </Grid>
              </Badge>
            ))}
            {(blocked && (
              <Badge badgeContent=" " variant="dot" classes={{ badge: 'morado' }}>
                {(automatic > 0 && (
                  <Badge badgeContent=" " variant="dot" color="secondary" />
                ))}
                <Grid item>
                  {day.getDate()}
                </Grid>
              </Badge>
            ))}
          </Paper>
        );
      } else if (isRed) {
        dateTile = (
          <Paper
            // eslint-disable-next-line no-nested-ternary
            className={assignClasses(
              isSelected,
              isToday,
              dayHasReservations,
              'Red',
            )}
          >
            {(waitinglist && (
              <Badge badgeContent=" " variant="dot" color="primary">
                {(automatic > 0 && (
                  <Badge badgeContent=" " variant="dot" color="secondary" />
                ))}
                <Grid item>
                  {day.getDate()}
                </Grid>
              </Badge>
            ))}
            {(!waitinglist && (
              <>
                {(automatic > 0 && (
                  <Badge badgeContent=" " variant="dot" color="secondary">
                    <Grid item>
                      {day.getDate()}
                    </Grid>
                  </Badge>
                ))}
                {(automatic <= 0 && (
                  <Grid item>
                    {day.getDate()}
                  </Grid>
                ))}
              </>
            ))}
          </Paper>
        );
      } else if (isYellow) {
        dateTile = (
          <Paper
            // eslint-disable-next-line no-nested-ternary
            className={assignClasses(
              isSelected,
              isToday,
              dayHasReservations,
              'Yellow',
            )}
          >
            {(waitinglist && (
              <Badge badgeContent=" " variant="dot" color="primary">
                {(automatic > 0 && (
                  <Badge badgeContent=" " variant="dot" color="secondary" />
                ))}
                <Grid item>
                  {day.getDate()}
                </Grid>
              </Badge>
            ))}
            {(!waitinglist && (
              <>
                {(automatic > 0 && (
                  <Badge badgeContent=" " variant="dot" color="secondary">
                    <Grid item>
                      {day.getDate()}
                    </Grid>
                  </Badge>
                ))}
                {(automatic <= 0 && (
                  <Grid item>
                    {day.getDate()}
                  </Grid>
                ))}
              </>
            ))}
          </Paper>
        );
      } else {
        dateTile = (
          <Paper
            // eslint-disable-next-line no-nested-ternary
            className={isSelected ? classes.selectedDayPaper
              : isToday ? classes.todayPaper : classes.normalDayPaper}
          >
            <Grid item>
              <br />
            </Grid>
            {(waitinglist && (
              <Badge badgeContent=" " variant="dot" color="primary">
                {(automatic > 0 && (
                  <Badge badgeContent=" " variant="dot" color="secondary" />
                ))}
                <Grid item>
                  {day.getDate()}
                </Grid>
              </Badge>
            ))}
            {(!waitinglist && (
              <>
                {(automatic > 0 && (
                  <Badge badgeContent=" " variant="dot" color="secondary">
                    <Grid item>
                      {day.getDate()}
                    </Grid>
                  </Badge>
                ))}
                {(automatic <= 0 && (
                  <Grid item>
                    {day.getDate()}
                  </Grid>
                ))}
              </>
            ))}
          </Paper>
        );
      }
    } else {
      dateTile = (
        <Paper className={classes.notInThisMonthDayPaper}>
          <Grid item>
            <br />
          </Grid>
          <Grid item style={{ color: 'lightGrey' }}>
            {' '}
            {day.getDate()}
          </Grid>
        </Paper>
      );
    }
    return dateTile;
  };
  const myToolbar = () => (
    <Box>
      <div>
        <div className="leyendarow">
          <b>Los puntos grandes:</b>
        </div>
        <div className="leyendarow">
          <span className="leyenda rojo" />
          <span>
            <b>Punto rojo del calendario:</b>
            &nbsp;
            son reservas que necesitan ser contestadas.
          </span>
        </div>
        <div className="leyendarow">
          <span className="leyenda azuloscuro" />
          <span>
            <b>Punto azul del calendario:</b>
            &nbsp;
            desaparece cuando marcas el dia (para chequear la nueva reserva).
            Estas reservas no se contestan.
          </span>
        </div>
        <div className="leyendarow">
          <b>Los días marcados en:</b>
        </div>
        <div className="leyendarow">
          <span className="leyenda rojo" />
          <span>
            <b>ROJO</b>
            &nbsp;
            Son los dias con muchas Reservas
          </span>
        </div>
        <div className="leyendarow">
          <span className="leyenda naranja" />
          <span>
            <b>NARANJA</b>
            &nbsp;
            Son los dias con reservas moderadas
          </span>
        </div>
        <div className="leyendarow">
          <span className="leyenda verde" />
          <span>
            <b>VERDE</b>
            &nbsp;
            Son los dias con pocas reservas
          </span>
        </div>
      </div>
    </Box>
  );
  const renderWithData = () => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <DatePicker
        ToolbarComponent={myToolbar}
        className="reservations-calendar joshdata"
        cancelLabel=""
        okLabel=""
        value={currentDate}
        onChange={setCurrentDate}
        autoOk
        onAccept={(newDate) => { onNewDateSelected(newDate); }}
        onMonthChange={(month) => { onMonthChange(month); }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        // eslint-disable-next-line max-len
        renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)}
      />
    </MuiPickersUtilsProvider>
  );
  const renderWithoutData = () => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <DatePicker
        ToolbarComponent={myToolbar}
        className="reservations-calendar joshdata2"
        cancelLabel=""
        okLabel=""
        value={currentDate}
        onChange={setCurrentDate}
        autoOk
        onAccept={(newDate) => { onNewDateSelected(newDate); }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </MuiPickersUtilsProvider>
  );

  return (
    <>
      {!status.loading && status.hasData && renderWithData()}
      {!status.loading && !status.hasData && renderWithoutData()}
    </>
  );
};

FloormapDatepicker.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  apiCallConfig: PropTypes.shape({
    date: PropTypes.string.isRequired,
  }).isRequired,
  // setNewDate: PropTypes.func.isRequired,
};

export default FloormapDatepicker;
