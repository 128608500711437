/* eslint-disable */



import React, { useState, useEffect, useContext } from 'react';
import { HuePicker, AlphaPicker } from 'react-color';
import FloormapContext from '../../layouts/Floormap/FloormapContext/FloormapContext';
import FloormapDistribucionContext from '../FloormapDistribucion/FloormapDistribucionContext/FloormapDistribucionContext';
import { Switch } from 'antd';

import IconButton from '@material-ui/core/IconButton';

import letra1 from '../../images/floormapElements/botonesAndOpciones/letra-1.png';
import letra2 from '../../images/floormapElements/botonesAndOpciones/letra-2.png';



import './FloormapTextMenu.scss';

import {
  Button,
  Box,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  Accordion,
  Container,
  Grid,
  TextField,
  CircularProgress,
  Hidden,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  NativeSelect,
  Checkbox,
  InputLabel,
  Typography,
  Menu
} from '@material-ui/core';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { array } from 'prop-types';

const FloormapTextMenu = () => {
  
  const { arrayRehacer, setArrayRehacer, arrayDeshacer, setArrayDeshacer, id, setId, images, setImages, selectedItem, setSelectedItem, imageNode, setImageNode } = useContext(FloormapDistribucionContext);

  //Menu ancho de contorno

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const abrirMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const cerrarMenu = () => {
    setAnchorEl(null);
  };

  const selectFontSize = (size) => {
    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = imageNode.attrs.idNum;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.fontSize = size;
        imageNode.attrs.fontSize = image.fontSize;
      }
      return image;
    }));

    setAnchorEl(null);
  }
  
  const reduceFontSize = (size) => {
    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = imageNode.attrs.idNum;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.fontSize = image.fontSize - 2;
        imageNode.attrs.fontSize = image.fontSize;
      }
      return image;
    }));
  }
  
  const increaseFontSize = (size) => {
    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = imageNode.attrs.idNum;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.fontSize = image.fontSize + 2;
        imageNode.attrs.fontSize = image.fontSize;
      }
      return image;
    }));
  }
  

  return(
    <>
      <div className='dFlex flex-row align-center '>
        <IconButton
          variant="outlined"
          className='floormap-icon2'
          onClick={abrirMenu}
        >
          <Typography variant="h6">{imageNode.attrs.fontSize}</Typography>
          <ExpandMoreIcon htmlColor={"black"}/>
        </IconButton>
        <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={cerrarMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem className='container-letra-image' onClick={() => {selectFontSize(20)}}>
          <Typography >20</Typography>
        </MenuItem>
        <MenuItem className='container-letra-image' onClick={() => {selectFontSize(22)}}>
          <Typography >22</Typography>
        </MenuItem>
        <MenuItem className='container-letra-image' onClick={() => {selectFontSize(24)}}>
          <Typography >24</Typography>
        </MenuItem>
        <MenuItem className='container-letra-image' onClick={() => {selectFontSize(26)}}>
          <Typography >26</Typography>
        </MenuItem>
        <MenuItem className='container-letra-image' onClick={() => {selectFontSize(28)}}>
          <Typography >28</Typography>
        </MenuItem>
        <MenuItem className='container-letra-image' onClick={() => {selectFontSize(30)}}>
          <Typography >30</Typography>
        </MenuItem>
      </Menu>
      <IconButton
        variant="outlined"
        className='floormap-icon'
        onClick={increaseFontSize}
        disabled={imageNode.attrs.fontSize >= 30}
      >
        <img src={letra2} alt="relleno" width="100%" height="100%" />
      </IconButton>
      <IconButton
        variant="outlined"
        className='floormap-icon'
        onClick={reduceFontSize}
        disabled={imageNode.attrs.fontSize <= 20}
      >
        <img src={letra1} alt="relleno" width="100%" height="100%" />
      </IconButton>
      </div>
    </>
  )

}

export default FloormapTextMenu;