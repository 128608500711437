/* eslint-disable import/no-named-as-default-member */
// eslint-disable-next-line import/no-named-as-default
// import moment from 'moment';

import client from '../utils/client';

// eslint-disable-next-line import/no-named-as-default
// import TokenManager from './utils/TokenManager';

// Crea un Floormap o comprueba si existe y lo devuelve

const getFloormap = () => client('floormap/FloormapValidation')
  .then((response: any) => response);

const getFloors = (floormapId) => client(`floormap/floors/${floormapId}/ViewsRestaurant`)
  .then((response: any) => response);

const getFloorItems = (floorId) => client(`floormap/floor/${floorId}/items`)
  .then((response: any) => response);

// Guarda o actualiza los datos de un floor
const setFloorItems = (floorId, data) => client(`floormap/${floorId}/updateFloor`,
  {
    body: data,
    method: 'PUT',
  })
  .then((response: any) => {
    console.log(response);
    return response;
  });

// Obtiene la escala de un floor
const getScaleFloor = (floorId) => client(`floormap/${floorId}/getFloorScale`)
  .then((response: any) => response);

// Obtener clientes de un restaurant
const obtenerClientes = () => client('floormap/getClientsByRestaurantId')
  .then((response: any) => response);

// Obtener registro de un cliente
const registroCliente = (clientId) => client(`floormap/clients/${clientId}/reservations`)
  .then((response: any) => response);

// Obtener las mesas de una reservación
const getMesasForReservation = (encryptId) => client(`floormap/mesas-by-encrypt/${encryptId}`)
  .then((response: any) => response);

// Obtener las mesas de una reservación
const getAllItems = () => client('floormap/getItemsALLId')
  .then((response: any) => response);

// Obtener el numero de reservaciones de una zona
const getNumReservations = (zoneId) => client(`floormap/reservationAllZone/${zoneId}`)
  .then((response: any) => response);

// Guardar la escala de un floor
const setScaleFloor = (floorId, data) => client(`floormap/${floorId}/updateFloorScale`,
  {
    body: data,
    method: 'PUT',
  })
  .then((response: any) => {
    console.log(response);
    return response;
  });

// Guardar la escala de un floor
const setComentario = (data) => client('floormap/reservationUpdateComment',
  {
    body: data,
    method: 'PUT',
  })
  .then((response: any) => {
    console.log(response);
    return response;
  });

// Guardar la escala de un floor
const setReserva = (data) => client('floormap/reservationUpdateDate',
  {
    body: data,
    method: 'PUT',
  })
  .then((response: any) => {
    console.log(response);
    return response;
  });

// Emparejar una mesa y una reserva
const setMesaReserva = (idNum, reservationId) => client(`floormap/createReservaMesa/${idNum}/${reservationId}`,
  {
    method: 'POST',
  })
  .then((response: any) => {
    console.log(response);
    return response;
  });

// Emparejar una mesa y una reserva
const unsetMesaReserva = (idNum, reservationId) => client(`floormap/deleteReservaMesa/${idNum}/${reservationId}`,
  {
    method: 'POST',
  })
  .then((response: any) => {
    console.log(response);
    return response;
  });

// Emparejar varias mesas y una reserva
const unsetVariasMesasReserva = (data) => client('floormap/unreserveMultipleTables',
  {
    body: data,
    method: 'POST',
  })
  .then((response: any) => {
    console.log(response);
    return response;
  });

// Emparejar varias mesas y una reserva
const setVariasMesasReserva = (data) => client('floormap/CreatedReservationAutomatic',
  {
    body: data,
    method: 'POST',
  })
  .then((response: any) => {
    console.log(response);
    return response;
  });

// Obtiene la escala de un floor
const getInfoRestaurant = () => client('restaurant')
  .then((response: any) => response);

// Cambiar visibilidad
const cambiarVisibilidad = (data) => client('floormap/UpdatedFloorVisible',
  {
    body: data,
    method: 'PUT',
  })
  .then((response: any) => {
    console.log(response);
    return response;
  });

export {
  getFloormap,
  getFloors,
  getFloorItems,
  setFloorItems,
  getScaleFloor,
  setScaleFloor,
  setMesaReserva,
  obtenerClientes,
  registroCliente,
  setVariasMesasReserva,
  getMesasForReservation,
  getInfoRestaurant,
  cambiarVisibilidad,
  unsetMesaReserva,
  unsetVariasMesasReserva,
  getNumReservations,
  setComentario,
  setReserva,
  getAllItems,
};
