/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  InputBase,
   MenuItem,
   Select
} from '@material-ui/core/';

import RestaurantContext from './RestaurantContext/RestaurantContext';

import * as RestaurantsApi from '../../api/restaurants/restaurants';
import prepareRestaurant from '../../utils/prepareRestaurant';

import isTruthy from '../../utils/isTruthy';
import TheMenu from '../../components/TheMenu';
import RestaurantTabs from '../../components/RestaurantTabs';
import RestaurantMenu from '../../components/RestaurantMenu';
import RestaurantDescription from '../../components/RestaurantDescription';
import RestaurantCapacity from '../../components/RestaurantCapacity';
import RestaurantSchedule from '../../components/RestaurantSchedule';
import RestaurantDayshours from '../../components/RestaurantDayshours';
import RestaurantImages from '../../components/RestaurantImages';
import RestaurantCover from '../../components/RestaurantCover';
import RestaurantAutomatic from '../../components/RestaurantAutomatic';
import RestaurantOffer from '../../components/RestaurantOffer';
import TableErrors from '../../components/TableErrors';
import './Restaurant.scss';
import { set } from 'date-fns';

const getCurrentTab = () => {
  const tab = localStorage.getItem('RestaurantCurrentTab');
  return tab !== null ? Number(tab) : 1;
};

const Restaurant = () => {
  const [state, setState] = useState({
    loading: true,
    error: null,
    success: false,
  });

  const [tab, setTab] = useState(getCurrentTab());
  const [descriptionData, setDescriptionData] = useState();
  const [photoData, setPhotoData] = useState();
  const [idData, setidData] = useState('');
  const [capacityData, setCapacityData] = useState();
  const [coverData, setCoverData] = useState('');
  const [menuData, setMenuData] = useState();
  const [linkData, setLinkData] = useState();
  //specialmenu   const [menuData, setMenuData] = useState();
  const [specialmenuData, setSpecialmenuData] = useState();

  const [scheduleData, setScheduleData] = useState();
  const [reload, setReload] = useState(false);
  const [acceptAutomaticReservation, setAcceptAutomaticReservation] = useState(false);
  // offers data
  const [offersData, setOffersData] = useState();
  const [hasOffer, setHasOffer] = useState(false);
  const [automaticReservationsData, setAutomaticReservationsData] = useState();
  const renderError = () => {
    const errorData = {
      message: state.error,
      action: () => true,
      buttonText: 'Intentar de nuevo',
      image: 'broken',
    };
    return (
      <TableErrors data={errorData} action={setReload} />
    );
  };

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
    setState({
      loading: true,
      error: null,
      success: false,
    });
    const fetchRestaurantData = async () => {
      try {
        const { success, data, message } = await RestaurantsApi.getRestaurantData();
        setidData(data.id);
        if (isTruthy(success) && success === 1) {
          await prepareRestaurant(data).then((preparedData) => {

            setDescriptionData(preparedData.description);
            setPhotoData(preparedData.photos);
            setCapacityData(preparedData.capacity);
            setCoverData(preparedData.cover);
            setMenuData(preparedData.menu);
            setSpecialmenuData(preparedData.specialmenu);
            setLinkData(preparedData.link);
            setScheduleData(preparedData.schedule);
            setCoverData(preparedData.cover);
            setOffersData(preparedData.offers);
            setHasOffer(preparedData.hasOffer);
            setAutomaticReservationsData(preparedData.automaticReservations);
            setAcceptAutomaticReservation(preparedData.acceptAutomaticReservation);

            console.log(preparedData);
            setState({
              loading: false,
              error: null,
              success: true,
            });
          });
        } else {
          setState({
            loading: false, error: message, success: false,
          });
        }
      } catch (error) {
        console.log(error);
        setState({
          loading: false, error: error.message, success: false, data: [],
        });
      }
    };
    fetchRestaurantData();
  }, [reload]);

  const renderLoading = () => (
    <div>Loading...</div>
  );

  const renderTabs = () => (
    <>
      <Box
        component="div"
        className="w100 dFlex flex-row align-center justify-between"
      >
        <RestaurantContext.Provider value={{ tab, setTab }}>
          <RestaurantTabs />
        </RestaurantContext.Provider>
      </Box>
    </>
  );
  const renderDescription = () => (
    console.log(descriptionData),
    <>
      <RestaurantContext.Provider value={{ descriptionData }}>
        <RestaurantDescription setReload={setReload} />
      </RestaurantContext.Provider>
    </>
  );

  const renderPhotos = () => (
    <>
      <RestaurantContext.Provider value={{ photoData }}>
        <RestaurantImages setReload={setReload} />
      </RestaurantContext.Provider>
    </>
  );
  const renderCapacity = () => (
    <>
      <RestaurantContext.Provider value={{ capacityData }}>
        <RestaurantCapacity idData={idData} setReload={setReload} />
      </RestaurantContext.Provider>
    </>
  );
  const renderMenu = () => (
    <>
      <RestaurantContext.Provider value={{ menuData, linkData, specialmenuData }}>
        <RestaurantMenu setReload={setReload} />
      </RestaurantContext.Provider>
    </>
  );
  const renderSchedule = () => (
    <>
      <div>
        <RestaurantContext.Provider value={{ scheduleData }}>
          <RestaurantSchedule setReload={setReload} />
        </RestaurantContext.Provider>
      </div>
    </>
  );
  const renderCover = () => (
    <>
      <div>
        <RestaurantContext.Provider value={{ coverData }}>
          <RestaurantCover setReload={setReload} />
        </RestaurantContext.Provider>
      </div>
    </>
  );
  const renderAutomatic = () => (
    <>
      <div>
        <RestaurantContext.Provider value={{ acceptAutomaticReservation, automaticReservationsData }}>
          <RestaurantAutomatic setReload={setReload} />
        </RestaurantContext.Provider>
      </div>
    </>

  );
  const renderReservationsday = () => (
    <>
      <div>
        <RestaurantContext.Provider value={{ scheduleData }}>
          <RestaurantDayshours setReload={setReload} />
        </RestaurantContext.Provider>
      </div>
    </>
  );
  const renderOffer= () => (
    <>
      <div>
        <RestaurantContext.Provider value={{offersData, hasOffer }}>
          <RestaurantOffer setReload={setReload} />
        </RestaurantContext.Provider>
      </div>
    </>
  );

  return (
    <>
      <TheMenu setIsCalendarOpen={() => { }} />
      <Container
        className="restaurant layout px-0"
      >
        <Box
          component="div"
          className="restaurant__header pr-1 pl-1 dFlex flex-row align-center justify-between"
        >
          <div className="dFlex flex-row">
            <Typography variant="h5" className="restaurant__title font__commutter">
              INFORMACIÓN DEL RESTAURANTE
            </Typography>
          </div>
        </Box>
        <Box
          component="div"
          className="restaurant__tabs-container mt-2 dFlex flex-column align-center justify-center"
        >
          {state.loading && renderLoading()}
          {!state.loading && state.success && renderTabs()}
        </Box>
        <Box
          component="div"
          className="mt-2 dFlex flex-column align-center justify-center"
        >
          {!state.loading && state.success && tab === 1 && renderDescription()}
          {!state.loading && state.success && tab === 2 && renderPhotos()}
          {!state.loading && state.success && tab === 3 && renderCapacity()}
          {!state.loading && state.success && tab === 4 && renderMenu()}
          {!state.loading && state.success && tab === 5 && renderSchedule()}
          {!state.loading && state.success && tab === 6 && renderCover()}
          {!state.loading && state.success && tab === 7 && renderAutomatic()}
          {!state.loading && state.success && tab === 8 && renderReservationsday()}
          {!state.loading && state.error && renderError()}
        </Box>
      </Container>
    </>
  );
};

export default Restaurant;
