/* eslint-disable */
import React, { useState, useEffect } from 'react';
// lodash is imported from create-react-app, but we don't have it on our project dependecies.
// eslint-disable-next-line import/no-extraneous-dependencies
// import {
//   // isEmpty,
//   throttle,
// } from 'lodash';

import { navigate } from '@reach/router';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
  Hidden,
  Popper,
  FormGroup,
  FormControlLabel,
  Tabs,
  Tab,
  Badge,
} from '@material-ui/core/';
import 'moment/locale/es';
import TheMenu from '../../components/TheMenu';

import { Stage, Layer, Star, Text } from 'react-konva';



import FloormapContext from './FloormapContext/FloormapContext';
import FloormapTabs from '../../components/FloormapTabs';

import 'antd/dist/antd.css';
import './Floormap.scss';
import FloormapDistribucion from '../../components/FloormapDistribucion/FloormapDistribucion';
import FloormapGestionReservaciones from '../../components/FloormapGestionReservaciones/FloormapGestionReservaciones';
import FloormapDiagramaDisponibilidad from '../../components/FloormapDiagramaDisponibilidad/FloormapDiagramaDisponibilidad';

const getCurrentTab = () => {
  const tab = localStorage.getItem('FloormapCurrentTab');
  return tab !== null ? Number(tab) : 1;
};

const Floormap = () => {
  const [state, setState] = useState({
    loading: false,
    error: null,
    success: true,
  });

  const [tab, setTab] = useState(getCurrentTab());
  const [reload, setReload] = useState(false);

  const renderError = () => {
    const errorData = {
      message: state.error,
      action: () => true,
      buttonText: 'Intentar de nuevo',
      image: 'broken',
    };
    return (
      <TableErrors data={errorData} action={setReload} />
    );
  };

  const renderLoading = () => (
    <div>Loading...</div>
  );

  const renderDistribucion = () => (
  
    <>
      <FloormapContext.Provider >
        <FloormapDistribucion />
      </FloormapContext.Provider>
    </>
  );

  const renderDiagramaDisponibilidad = () => (
  
    <>
      <FloormapContext.Provider >
        <FloormapDiagramaDisponibilidad />
      </FloormapContext.Provider>
    </>
  );

  const renderGestionReservaciones = () => (
  
    <>
      <FloormapContext.Provider >
        <FloormapGestionReservaciones />
      </FloormapContext.Provider>
    </>
  );

  const renderTabs = () => (
    <>
      <Box
        component="div"
        className="w100 dFlex flex-row align-center justify-between"
      >
        <FloormapContext.Provider value={{ tab, setTab }}>
          <FloormapTabs />
        </FloormapContext.Provider>
      </Box>
    </>
  );

  return (
    <>
      <TheMenu />

      <Container className="reservations layout px-0 containerFluid"
      >
        <Box
          component="div"
          className="pr-1 pl-1 mt-2 dFlex flex-row justify-between align-center"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" className="reservations-create__title font__commutter floormap__titulo">
                MAPA
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          component="div"
          className="restaurant__tabs-container mt-1 dFlex flex-column align-center justify-center"
        >
          {state.loading && renderLoading()}
          {!state.loading && state.success && renderTabs()}
        </Box>
        <Box
          component="div"
          className="mt-2 dFlex flex-column align-center justify-center"
        >
          {!state.loading && state.success && tab === 1 && renderDistribucion()}
          {!state.loading && state.success && tab === 2 && renderGestionReservaciones()}
          {!state.loading && state.success && tab === 3 && renderDiagramaDisponibilidad()}
        </Box>
      </Container>
    </>
  );
};

export default Floormap;
