/* eslint-disable */



import React, { useState, useEffect, useContext } from 'react';
import FloormapDiagramaDisponibilidadContext from './FloormapDiagramaDisponibilidadContext/FloormapDiagramaDisponibilidadContext';
import isTruthy from '../../utils/isTruthy';


import './FloormapDiagramaDisponibilidad.scss';

import * as FloormapApi from '../../api/restaurants/floormap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogImage from '../DialogImage';
import DefaulImage from '../../images/default.jpeg';
import mesa1 from '../../images/floormapElements/mesas/mesa1.png';
import DialogEmail from '../DialogEmail';
import { Tooltip, withStyles } from '@material-ui/core/';



import {
  Button,
  Box,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  Accordion,
  Container,
  Grid,
  TextField,
  CircularProgress,
  Hidden,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  NativeSelect,
  Checkbox,
  InputLabel,
  Typography,
  Modal
} from '@material-ui/core';
import prepareCategoryList from '../../utils/prepareCategoryList';
import MenuReservationStatus from '../MenuReservationStatus';
import Phone from '@material-ui/icons/Phone';

import moment from 'moment';


import FloormapItemMenu from '../FloormapItemMenu';
import FloormapShapeMenu from '../FloormapShapeMenu';
import FloormapTextMenu from '../FloormapTextMenu ';
import IconPeople from '../../images/categoryIcons/people.png';
import SearchIcon from '@material-ui/icons/Search';
import * as CatalogsApi from '../../api/catalogs';
import Clients from '../../layouts/Clients';
import SpinnerLoader from '../SpinnerLoader';

import IconButton from '@material-ui/core/IconButton';

const CustomColorIconButton = withStyles({
  root: {
    color: '#000000',
    paddingTop: '0px',
  },
  label: {
    color: '#000',
    border: 'solid 1px #000000',
    borderRadius: '20px',
    marginRight: '10px',
    paddingTop: '0px',
    height: '26px',
  },
})(IconButton);

const FloormapDiagramaDisponibilidad = () => {

  const [sizeScreen, setSizeScreen] = useState(window.screen.width);

  window.addEventListener('resize', () => {
    let timeout = false;
    const delay = 250;

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      setSizeScreen(window.screen.width);
    }, delay);
  });

  
  const getClients = async () => {
    try {
      setLoading(true);
      const { success, data } = await FloormapApi
        .obtenerClientes();
      console.log(data);
      if (isTruthy(success)) {
        setClients(data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const getReservations = async (id) => {
    try {
      setLoading(true);
      const { success, data } = await FloormapApi
        .registroCliente(id);
      console.log(data);
      if (isTruthy(success)) {
        setReservations(data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    const calatogReservationStatus = localStorage.getItem('catalogReservationStatus');
    const getcatalogs = async () => {
      try {
        setLoading(true);
        const { success, data } = await CatalogsApi
          .catalogReservationStatus();
        console.log(data);
        if (isTruthy(success)) {
          setCatalogReservationStatus(data);
          localStorage.setItem('catalogReservationStatus', JSON.stringify(data));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    if (calatogReservationStatus === null || calatogReservationStatus === 'undefined') {
      getcatalogs();
    } else {
      setCatalogReservationStatus(JSON.parse(calatogReservationStatus));
    }

    setLoading(false);
    getClients();
  }, []);



  const [catalogReservationStatus, setCatalogReservationStatus] = useState({});



  //Estado de recarga

  const [reload, setReload] = useState(false);

  const [loading, setLoading] = useState(true);


  // settear configuración de la API

  const currentCallConfig = () => {
    const retrieveReservationsConfig = localStorage.getItem('ReservationsConfig');
    const retrieveConfigDate = localStorage.getItem('ReservationsConfigDate');
    if (retrieveConfigDate === null) { localStorage.setItem('ReservationsConfigDate', moment().format('YYYY-MM-DD hh:mm a')); }
    const retrieveConfigZone = localStorage.getItem('ReservationsConfigZone');
    const retrieveConfigSortCategory = localStorage.getItem('ReservationsConfigSortCategory');
    const retrieveConfigSortDirection = localStorage.getItem('ReservationsConfigSortDirection');
    const config = {
      lastPage: retrieveReservationsConfig !== null
        ? JSON.parse(retrieveReservationsConfig).lastPage : 1,
      currentPage: retrieveReservationsConfig !== null
        ? JSON.parse(retrieveReservationsConfig).currentPage : 1,
      perPage: retrieveReservationsConfig !== null
        ? Number(JSON.parse(retrieveReservationsConfig).perPage)
        : 100,
      total: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).total : 0,
      from: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).from : 1,
      to: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).to : 5,
      options: [5, 25, 100],
      date: retrieveConfigDate !== null ? retrieveConfigDate.substring(0, 10) : moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      time: retrieveConfigDate !== null ? retrieveConfigDate.substring(11, retrieveConfigDate.length) : moment(new Date(), 'hh:mm a').format('hh:mm a'),
      zone: retrieveConfigZone !== null ? retrieveConfigZone : 'all',
      sortCategory: retrieveConfigSortCategory !== null ? retrieveConfigSortCategory : 'fecha',
      sortDirection: retrieveConfigSortDirection !== null ? retrieveConfigSortDirection : 'asc',
      search: '',
    };
    return config;
  };

  

  const [apiCallConfig, setApiCallConfig] = useState(currentCallConfig());

  const [pagination] = useState(apiCallConfig);



  const [reservations, setReservations] = useState([]);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [mostrarReservas, setMostrarReservas] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const [imageDialogData, setImageDialogData] = useState('');

  const [states, setStates] = useState({
    loading: true,
    error: null,
    reservations: [],
    pardepanBlocked: [],
    reservationsBlocked: { reservations: [], cancelled: [] },
    capacity: [],
    paginator: {},
    currentFilter: 'Reservas Confirmadas',
    reservationsVisible: true,
  });

  

  const [emailDialog, setEmailDialog] = useState(false);
  const [emailDialogData, setEmailDialogData] = useState({ name: '', email: '', phone: '' });

  const openemailDialog = (name, email, phone) => {
    setEmailDialogData({ name, email, phone });
    setEmailDialog(true);
  };

  const openImageDialog = (image) => {
    setImageDialogData(image);
    setImageDialog(true);
  };

  const closeEmailDialog = () => {
    setEmailDialog(false);
  };

  const closeImageDialog = () => {
    setImageDialog(false);
  };

  const renderImageDialog = () =>{
    return(
      <DialogImage
        isOpen={imageDialog}
        onNo={closeImageDialog}
        data={imageDialogData}
      />
    )
  }

  const buscarReservaciones = (cliente) => {
    console.log(cliente);
    getReservations(cliente);
    setMostrarReservas(true);
  }

  const reservasPc = () => {
    return(
      <div className={`${sizeScreen < 1024 ? "scroll" : "w100"} border-menu-drag mb-2`}>
        <div className='pl-1 border-menu-drag--header margin-image'>
          <div className='w15'>
            <Typography className='font__commutter font-size-commutter'>Reservaciones</Typography>
            <Typography className='tiny-font2'>por hora de reserva</Typography>
          </div>

          <div className='contacto-section w10'>
            <Typography className='font__commutter font-size-commutter'>NOMBRE</Typography>
          </div>

          <div className='contacto-section w10'>
            <Typography className='font__commutter font-size-commutter'>FECHA</Typography>
          </div>

          <div className='contacto-section w10'>
            <Typography className='font__commutter font-size-commutter'>CONTACTO</Typography>
          </div>


          <div className='zona-section w10'>
            <Typography className='font__commutter font-size-commutter'>ZONA</Typography>
          </div>

          <div className='personas-section w5'>
            <img src={IconPeople} alt="asistentes" className="iconpeople" />
          </div>

          <div className='status-section w20'>
            <Typography className='font__commutter font-size-commutter'>STATUS</Typography>
          </div>

          <div className='comentarios-section w10'>
            <Typography className='font__commutter font-size-commutter'>COMENTARIOS</Typography>
          </div>
      
          <div className='visitas-section w10'>
            <Typography className='font__commutter font-size-commutter'>VISITAS</Typography>
          </div>
        </div>

        {reservations.map((item, index, arr) => (
        <>
          <div 
            className="border-menu-drag--section pl-1 margin-image"
          >
            <div className='w15 dFlex flex-row align-center'>
              <button type="button" className="photocontainer photocontainer2" width="100px" height="50px" onClick={() => openImageDialog((item.photo === null || item.photo === '') ? DefaulImage : ("https://api.puerta21.club/storage/app/prod/users//" + item.photo))}>
                {(item.photo === null || item.photo === '') ? (
                  <>
                    <img src={DefaulImage} alt="" width="100%" />
                  </>
                ) : (
                  <>
                    <img src={"https://api.puerta21.club/storage/app/prod/users//" + item.photo} alt="" width="100%" />
                  </>
                )}
              </button>
              <Typography className="pt-1e"><span className='bold-font font__commutter font-size-commutter'>{index+1}.</span></Typography>
            </div>
            <div className='box-text-reserve w10'>
              <Typography className='text-center'>
                {(item.firstName + item.middleName + item.lastName) !== null && (item.clientStatus === 'Pendiente' || item.clientStatus === 'Invitado') && (
                  <>
                    <span className='bold-font'>
                      {`${(item.firstName != null && item.firstName != "") ? item.firstName : ""} ${(item.middleName != null && item.middleName != "") ? item.middleName : ""} ${(item.lastName != null && item.lastName != "") ? item.lastName : ""}`}
                    </span>
                  </>
                )}
                {(item.firstName + item.middleName + item.lastName) !== null && (item.clientStatus === 'Aceptado' || item.clientStatus === 'Invitado') && (
                  <>
                    <span className='bold-font'>
                      {`${(item.firstName != null && item.firstName != "") ? item.firstName : ""} ${(item.middleName != null && item.middleName != "") ? item.middleName : ""} ${(item.lastName != null && item.lastName != "") ? item.lastName : ""}`}
                    </span>
                  </>
                )}
                {(item.firstName + item.middleName + item.lastName) !== null && item.clientStatus !== 'Aceptado' && item.clientStatus !== 'Invitado' && (
                  <>
                    <span className='bold-font'>
                      {`${(item.firstName != null && item.firstName != "") ? item.firstName : ""} ${(item.middleName != null && item.middleName != "") ? item.middleName : ""} ${(item.lastName != null && item.lastName != "") ? item.lastName : ""}`}
                    </span>
                  </>
                )}
                {(item.firstName + item.middleName + item.lastName) === null && (
                  <span className='bold-font'>
                    Sin datos
                  </span>
                )}
              </Typography>
            </div>
            
            <div className='box-text-reserve contacto-section w10'>
              <Typography className='tiny-font3'>{moment(item.reservationDate).format('YYYY-MM-DD')}</Typography>
              <Typography className='tiny-font3'>{moment(item.reservationDate).format('hh:mm a')}</Typography>
            </div>

            <div className='box-text-reserve contacto-section w10'>
              <Typography className='tiny-font3 text-overflow'>{item.cellPhone}</Typography>
              <Typography className='tiny-font3 text-overflow'>{item.email}</Typography>
            </div>
            
            <div className='box-text-reserve zona-section w10'>
              <Typography className='tiny-font3'>{item.tableTypeName != null ? item.tableTypeName : "Sin datos"}</Typography>
            </div>
            
            <div className='box-text-reserve personas-section w5'>
              <Typography className='tiny-font3'>{item.people}</Typography>
            </div>

            <div className='box-text-reserve status-section w20 tiny-font5'>
              {(item.reservationStatusName !== 'CANCELADO POR EL CLIENTE')
              ? (
                <MenuReservationStatus
                  id={item.id}
                  status={(item.reservationStatusName === 'EN ESPERA') ? 'ANOTADA POR EL RESTAURANTE' : (item.reservationStatusName === 'LISTA DE ESPERA') ? 'PENDIENTE POR CONTESTAR' : item.reservationStatusName}
                  type={item.type}
                  catalog={catalogReservationStatus}
                  reservationDate={item.dateRaw}
                  setNewStatus={() => {
                    setApiCallConfig({
                      ...pagination,
                    });
                  }}
                />
              ) : (
                <>
                  <span className="canceled">
                    {item.reservationStatusName}
                  </span>
                </>
              )}
            </div>

            <div className='box-text-reserve comentarios-section w10'>
              <Typography className='tiny-font3 text-overflow'>
                {item.comments.length > 0 ? item.comments : 'Sin comentarios'}
              </Typography>
            </div>

            <div className='box-text-reserve visitas-section w10'>
              <Typography className='tiny-font3'>{(item.visits) ? item.visits : 0}</Typography>
            </div>
          </div>
        </>
        ))}         
      </div>
    )
  }

  const reservasMovil = () => {
    return(
      <>
        {reservations.map((item, index, arr) => (
          <>
            <div 
              key={index}
              className={`border-menu-drag--section`}
              
            >
              <div className='dflex flex-column'>
                <div className='dflex flex-row align-center justify-between'>
                  <div className='w30'>
                    <button type="button" className="photocontainer photocontainer3" width="100px" height="50px" onClick={(e) => {e.stopPropagation(); openImageDialog((item.photo === null || item.photo === '') ? DefaulImage : ("https://api.puerta21.club/storage/app/prod/users//" + item.photo));}}>
                      {(item.photo === null || item.photo === '') ? (
                        <>
                          <img src={DefaulImage} alt="" width="100%" />
                        </>
                      ) : (
                        <>
                          <img src={"https://api.puerta21.club/storage/app/prod/users//" + item.photo} alt="" width="100%" />
                        </>
                      )}
                    </button>
                  </div>
                  <div className='w69'>
                      <Typography>
                        {(item.firstName + item.middleName + item.lastName) !== null && (item.clientStatus === 'Pendiente' || item.clientStatus === 'Invitado') && (
                          <>
                            <span className='bold-font'>
                              {`${(item.firstName != null && item.firstName != "") ? item.firstName : ""} ${(item.middleName != null && item.middleName != "") ? item.middleName : ""} ${(item.lastName != null && item.lastName != "") ? item.lastName : ""}`}
                            </span>
                          </>
                        )}
                        {(item.firstName + item.middleName + item.lastName) !== null && (item.clientStatus === 'Aceptado' || item.clientStatus === 'Invitado') && (
                          <>
                            <span className='bold-font'>
                              {`${(item.firstName != null && item.firstName != "") ? item.firstName : ""} ${(item.middleName != null && item.middleName != "") ? item.middleName : ""} ${(item.lastName != null && item.lastName != "") ? item.lastName : ""}`}
                            </span>
                          </>
                        )}
                        {(item.firstName + item.middleName + item.lastName) !== null && item.clientStatus !== 'Aceptado' && item.clientStatus !== 'Invitado' && (
                          <>
                            <span className='bold-font'>
                              {`${(item.firstName != null && item.firstName != "") ? item.firstName : ""} ${(item.middleName != null && item.middleName != "") ? item.middleName : ""} ${(item.lastName != null && item.lastName != "") ? item.lastName : ""}`}
                            </span>
                          </>
                        )}
                        {(item.firstName + item.middleName + item.lastName) === null && (
                          <span className='bold-font'>
                            Sin datos
                          </span>
                        )}
                      </Typography>
                      <Typography className='tiny-font3'>{item.people} Personas</Typography>
                      <Typography className='tiny-font3'>{moment(item.reservationDate).format('hh:mm a')} - {moment(item.reservationDate).format('YYYY-MM-DD')}</Typography>
                      <Typography className='tiny-font3'><span className='bold-font'>Zona</span>: {item.tableTypeName != null ? item.tableTypeName : "Sin datos"}</Typography>
                      <Typography className='tiny-font3'><span className='bold-font'>Visitas</span>: {(item.visits) ? item.visits : 0}</Typography>
                      <Typography className='tiny-font3'><span className='bold-font'>Comentarios</span>: {item.comments.length > 0 ? item.comments : 'Sin comentarios'}</Typography>
                  </div>
                </div>
                <div className='dflex flex-row'>
                  <div className='box-text-reserve categoria-section w50'>
                    <CustomColorIconButton size="small" onClick={(e) => {
                      e.stopPropagation();
                      openemailDialog(item.name, item.email, item.cellPhone);
                      }}
                    >
                      <Phone className="contacticon" />
                      <span className="contacttext">
                        CONTACTO
                      </span>
                    </CustomColorIconButton>
                    
                  </div>
                  <div onClick={(e) => {e.stopPropagation();}} className='box-text-reserve categoria-section w50'>
                    <MenuReservationStatus
                      id={item.id}
                      status={(item.reservationStatusName === 'EN ESPERA') ? 'ANOTADA POR EL RESTAURANTE' : (item.reservationStatusName === 'LISTA DE ESPERA') ? 'PENDIENTE POR CONTESTAR' : item.reservationStatusName}
                      type={item.type}
                      catalog={catalogReservationStatus}
                      reservationDate={item.dateRaw}
                      setNewStatus={() => {
                        setApiCallConfig({
                          ...pagination,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
        <DialogEmail
          isOpen={emailDialog}
          onNo={closeEmailDialog}
          data={emailDialogData}
        />
      </>
      
    )
  }

  return (
    <>
      {loading ?
        <SpinnerLoader />
        : null        
      }
      {renderImageDialog()}
      <Grid container className='justify-center mb-4'>
        <Grid item md={12} >
          <div id='boxSearch2' className='border mb-2e w100 search-box'>
            <SearchIcon htmlColor="#ddd"/>
            <TextField
              id="standard-basic"
              className='w100'
              onClick={() => {
                setReservations([]);
                setMostrarReservas(false)
              }}
              onChange={(e) => {
                setReservations([]);
                if(e.target.value.trim().length >= 4){
                  setMostrarReservas(false);
                  const busqueda = e.target.value.trim();
                  console.log(busqueda);
                  // clients.forEach((item) => {
                  //   console.log(item);
                    
                  // })
                  setFilteredClients(clients.filter((item) => {

                    let firstName = "";
                    let middleName = "";
                    let lastName = "";
                    let phone = "";
                    let email = "";

                    
                    if(item.firstName != null){
                      firstName = item.firstName;
                    }

                    if(item.middleName != null){
                      middleName = item.middleName;
                    }

                    if(item.lastName != null){
                      lastName = item.lastName;
                    }

                    if(item.phone != null){
                      phone = item.phone;
                    }

                    if(item.email != null){
                      email = item.email;
                    }
                    
                    let nombre = firstName  + " " + middleName + " " + lastName;
                    if(nombre.includes(busqueda) || phone.replaceAll("+", "").replaceAll( " ", "").replaceAll( "(", "").replaceAll( ")", "").includes(busqueda.replaceAll("+", "").replaceAll( " ", "").replaceAll( "(", "").replaceAll( ")", "")) || email.includes(busqueda)){
                      return true;
                    }
                    return false;
                  }))
                }else if(e.target.value.trim().length == 0){
                  setFilteredClients([]);
                }
                console.log(filteredClients);
              }}
              placeholder="Buscar por nombre o teléfono"
              inputProps={{className: "search-input"}}
              // onChange={throttle((e) => setNameForFilter(e.target.value), throttleTime)}
              // value={nameForFilter}
            />
            {/* <IconButton
              // onClick={(e) => { handlePopper(e); setNameForFilter(''); }}
              edge="start"
              color="primary"
              aria-label="menu"
              size="small"
            >
              <CloseIcon />
            </IconButton> */}
          </div>
          {!mostrarReservas ? 
            <>
              {filteredClients.map((item, index, arr) => (
                  <>
                    <div onClick={() => {
                      buscarReservaciones(item.id)
                      }} className={`border-menu-drag`} style={{width: "100%", marginBottom: "0.5rem"}}>
                      <div className={`search-client-option`}>
                        <Typography style={{textAlign: "center"}}>{`${(item.firstName != null && item.firstName != "") ? item.firstName : ""} ${(item.middleName != null && item.middleName != "") ? " " + item.middleName: ""} ${(item.lastName != null && item.lastName != "") ? " " + item.lastName : ""}${(item.email != null && item.email != "") ? " - " + item.email : ""}${(item.phone != null && item.phone != "") ? " - " + item.phone : ""}`}</Typography>
                      </div>              
                    </div>
                  </>
              ))}
            </>
            : null
          }
               
          
        </Grid>
        <Grid item md={12} className={`${sizeScreen < 1024 ? "scroll" : ""}`}>

        {mostrarReservas ? 
            <>
              {reservations.length > 0 && sizeScreen >= 1024 && reservasPc()}
              {reservations.length > 0 && sizeScreen < 1024 && reservasMovil()}
            </>
          : null
        }

          
        </Grid>
      </Grid>

    </>
  );
};

export default FloormapDiagramaDisponibilidad;