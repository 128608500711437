/* eslint-disable */
import React, { useState, useEffect } from 'react';
// lodash is imported from create-react-app, but we don't have it on our project dependecies.
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEmpty, throttle } from 'lodash';
import { saveAs } from 'file-saver';
import ReactExport from "react-data-export";
import {
  Box,
  Button,
  Container,
  // Grid,
  // Hidden,
  IconButton,
  TextField,
  Typography,
  Hidden,
  Popper,
  Backdrop,
  CircularProgress,
  makeStyles,
} from '@material-ui/core/';

import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
// import { saveAs } from 'file-saver';

import clientsTableFilter from '../../utils/clientsTableFilter';
import ClientsContext from './ClientsContext/ClientsContext';
import TableErrors from '../../components/TableErrors';
import * as ClientsApi from '../../api/restaurants/clients';
import prepareClientsTable from '../../utils/prepareClientsTable';
import SnackbarAlert from '../../components/SnackbarAlert';

import isTruthy from '../../utils/isTruthy';

import ClientsAccordion from '../../components/ClientsAccordion';
import ClientsTabs from '../../components/ClientsTabs';
import ClientsTable from '../../components/ClientsTable';
// import TablePaginationCustom from '../../components/TablePaginationCustom';
import TheMenu from '../../components/TheMenu';

import './Clients.scss';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const currentCallConfig = () => {
  const retrieveClientsConfig = localStorage.getItem('ClientsConfig');
  const config = {
    lastPage: retrieveClientsConfig !== null
      ? JSON.parse(retrieveClientsConfig).lastPage : 1,
    currentPage: 1,
    perPage: retrieveClientsConfig !== null
      ? Number(JSON.parse(retrieveClientsConfig).perPage)
      : 5,
    total: retrieveClientsConfig !== null ? JSON.parse(retrieveClientsConfig).total : 0,
    from: retrieveClientsConfig !== null ? JSON.parse(retrieveClientsConfig).from : 1,
    to: retrieveClientsConfig !== null ? JSON.parse(retrieveClientsConfig).to : 5,
    options: [5, 25, 100],
  };
  return config;
};


const Clients = () => {
  const throttleTime = 200;

  const [state, setState] = useState({
    loading: true,
    error: null,
    success: false,
    clients: [],
    paginator: {},
  });


  const [reload, setReload] = useState(false);

  const clientsTypeOfClient = localStorage.getItem('clientsTypeOfClient') ? localStorage.getItem('clientsTypeOfClient') : 'puerta21';
  const [typeOfClient, setTypeOfClient] = useState(clientsTypeOfClient);

  const [clients, setClients] = useState(state.clients);
  const [filteredClients, setFilteredClients] = useState(state.clients);

  const [apiCallConfig, setApiCallConfig] = useState(currentCallConfig());

  // Los siguientes son usados para configurar el buscador de la página
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopper = Boolean(anchorEl);
  const [nameForFilter, setNameForFilter] = useState('');

  // Configuración para el nackbar alert
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  // Configuracion para el backdrop/loader
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  // Configuracion para el popper de búsqueda
  const handlePopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const downloadReport = async () => {
    setOpenBackdrop(true);
    try {
      const response = await ClientsApi.exportClients(typeOfClient, nameForFilter, {
        responseType: 'blob',
      });

      if (response.status === 200) {
        const blob = new Blob([response.data]);
        saveAs(blob, 'reporte_clientes.xlsx'); // Usa file-saver para descargar el archivo

        setSnackbar({
          open: true,
          severity: 'success',
          text: 'Archivo generado correctamente',
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: response.data.data.error,
        });
      }
      handleCloseBackdrop();
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error.message || 'Error al descargar el archivo.',
      });
      handleCloseBackdrop();
    }
  };

  useEffect(() => {
    setState({
      loading: true,
      error: null,
      success: false,
      clients: [],
      paginator: {},
    });
    const fetchClients = async () => {
      const newConfig = currentCallConfig();
      try {
        const { success, data } = await ClientsApi
          .showAllClientsPages(typeOfClient, newConfig, 10);

        if (isTruthy(success)) {
          await prepareClientsTable(data.data).then((tableData) => {
            setClients(tableData);
            console.log("aaaaaaaaah");
            console.log(tableData);
            setFilteredClients(tableData);
            const newCallConfig = {
              currentPage: data.currentPage,
              from: data.from,
              perPage: data.perPage,
              lastPage: data.lastPage,
              to: data.to,
              total: data.total,
            };
            setState({
              loading: false,
              error: null,
              success: true,
              clients: tableData,
              paginator: newCallConfig,
            });
            localStorage.setItem('ClientsConfig', JSON.stringify(newCallConfig));
          });
        } else {
          setState({
            loading: false, error: data.error, success: false, clients: [], paginator: {},
          });
        }
      } catch (error) {
        console.log(error);
        setState({
          loading: false, error: error.message, success: false, clients: [], paginator: {},
        });
      }
    };

    fetchClients();
  }, [typeOfClient, apiCallConfig, reload]);
  const multiDataSet2 = [
    {
      columns: [
        { title: "NOMBRE", width: { wch: 30 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },
        { title: "EMAIL", width: { wch: 35 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },
        { title: "TELÉFONO", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },
        { title: "VISTIAS", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },

      ],
      data: clients.map(client => [
        client.fullName,
        client.email || 'N/A',  // Manejar caso en que no hay email
        client.phone || 'N/A' ,   // Manejar caso en que no hay teléfono
        client.visits || 'N/A'    // Manejar caso en que no hay teléfono

      ])
    }
  ];


  useEffect(() => {
    const newFilteredClients = isEmpty(nameForFilter)
      ? clients
      : clients.filter((client) => clientsTableFilter(client, nameForFilter));
    setFilteredClients(newFilteredClients);
  }, [nameForFilter, clients]);

  const renderLoading = () => (
    <div>Loading...</div>
  );

  const renderTab = () => (
    <Box
      component="div"
      className="w100 dFlex flex-row align-center justify-between"
    >
      <ClientsContext.Provider value={{ typeOfClient, setTypeOfClient }}>
        <ClientsTabs />
      </ClientsContext.Provider>
    </Box>
  );

  const renderError = () => {
    const errorData = {
      message: state.error,
      action: () => true,
      buttonText: 'Intentar de nuevo',
      image: 'broken',
    };
    return (
      <TableErrors data={errorData} action={setReload} />
    );
  };
  /*
    const renderTablePagination = () => (
      <Box
        component="div"
        className="clients-table__box w100 mb-5 dFlex flex-row flex-row align-center justify-end"
      >
        <ClientsContext.Provider value={{ apiCallConfig, state, setApiCallConfig }}>
          <TablePaginationCustom context="ClientsContext" />
        </ClientsContext.Provider>
      </Box>
    );
  */
  const renderTable = () => (
    <>
      <Hidden xsDown>
        <Box
          component="div"
          className="clients-table__box w100 dFlex flex-row align-center justify-between"
        >
          <ClientsContext.Provider value={
            { filteredClients, typeOfClient }
          }
          >
            <ClientsTable />
          </ClientsContext.Provider>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box
          component="div"
          className="clients-table__box w100 dFlex flex-row align-center justify-between"
        >
          <ClientsContext.Provider value={
            { filteredClients, typeOfClient }
          }
          >
            <ClientsAccordion />
          </ClientsContext.Provider>
        </Box>
      </Hidden>
    </>
  );

  const renderNoClients = () => {
    const errorData = {
      message: 'Aún no tienes clientes.',
      action: null,
      buttonText: null,
      image: 'notFound',
    };
    return (
      <TableErrors data={errorData} />
    );
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TheMenu setIsCalendarOpen={() => { }} />
      <Container
        className="clients layout px-0"
      >
        <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />

        <Box
          component="div"
          className="clients__header pr-1 pl-1 dFlex flex-row align-center justify-between"
        >
          <Hidden smUp>
            <IconButton
              onClick={handlePopper}
              edge="start"
              color="primary"
              aria-label="menu"
              size="small"
            >
              <SearchIcon />
            </IconButton>
          </Hidden>
          <div className="dFlex flex-row">
            <Hidden xsDown>
              <IconButton
                onClick={handlePopper}
                edge="start"
                color="primary"
                aria-label="menu"
                size="small"
                className="search-icon__button"
              >
                <SearchIcon />
              </IconButton>
            </Hidden>
            <Typography variant="h5" className="clients__title font__commutter">
              CLIENTES
            </Typography>
          </div>
          <Popper
            className="clients__searchBar"
            open={openPopper}
            anchorEl={anchorEl}
            placement="right"
            disablePortal={false}
            transition
            modifiers={{
              flip: {
                enabled: true,
              },
              preventOverflow: {
                enabled: false,
                boundariesElement: 'scrollParent',
                hide: false,
              },
            }}
          >
            <div>

              <TextField
                id="standard-basic"
                placeholder="Buscar"
                onChange={throttle((e) => setNameForFilter(e.target.value), throttleTime)}
                value={nameForFilter}
              />
              <IconButton
                onClick={() => { handlePopper(); setNameForFilter(''); }}
                edge="start"
                color="primary"
                aria-label="menu"
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Popper>
         {/*
<Hidden xsDown>
  <Button
    onClick={downloadReport} // Puedes simplificar la llamada
    variant="contained"
    color="primary"
  >
    DESCARGAR REPORTE
  </Button>
</Hidden>
*/}     <Hidden xsDown>
          <ExcelFile element={ <Button

    variant="contained"
    color="primary"
  >
    DESCARGAR REPORTE
  </Button>}>
            <ExcelSheet dataSet={multiDataSet2} name="Reservaciones">
            </ExcelSheet>
          </ExcelFile>
          </Hidden>
          <Hidden smUp>
            {/*
            <IconButton
              onClick={() => { downloadReport(); }}
              edge="start"
              color="primary"
              aria-label="menu"
              size="small"
            >
              <SaveAltIcon />
            </IconButton>*/}
             <ExcelFile element={ <IconButton
edge="start"
color="primary"
aria-label="menu"
size="small"
>
<SaveAltIcon />
</IconButton>}>
        <ExcelSheet dataSet={multiDataSet2} name="Reservaciones">
        </ExcelSheet>
      </ExcelFile>
          </Hidden>
        </Box>

        <Box
          component="div"
          className="clients__tabs-container mt-2 dFlex flex-column align-center justify-between"
        >
          {!state.loading && renderTab()}
          {!state.loading && state.error && renderError()}
          {!state.loading && filteredClients.length > 0 && renderTable()}
          {!state.loading
            && state.clients.length === 0
            && !state.error
            && renderNoClients()}
          {state.loading && renderLoading()}
        </Box>
      </Container>
    </>
  );
};

export default Clients;
