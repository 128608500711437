import React from 'react';
import vegano from '../../images/categoryIcons/vegano.png';
import internacional from '../../images/categoryIcons/internacional.png';
import vip from '../../images/categoryIcons/vip.png';
import blacklist from '../../images/categoryIcons/blacklist.png';
import repeat from '../../images/categoryIcons/repeat.png';

const prepareCategoryList = (category: any) => {
  let icon;
  switch (category.string) {
    case 'Vegano':
      icon = vegano;
      break;

    case 'Internacional':
      icon = internacional;
      break;

    case 'Vip':
      icon = vip;
      break;

    case 'Blacklist':
      icon = blacklist;
      break;

    case 'Cliente Habitual':
      icon = repeat;
      break;

    default:
      icon = vip;
      break;
  }
  return (
    <div key={category.string} className="dFlex text-overflow flex-row justify-center align-start nowrap">
      <img
        alt={`Icon ${category.string}`}
        className="img-fuid category-icon"
        src={icon}
      />
      <div className="text-overflow">
        {(category.string === 'Blacklist') ? 'No deseado' : category.string}

      </div>
    </div>
  );
};

export default prepareCategoryList;
